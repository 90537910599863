import type { PosReducer } from './interfaces';

const initialState: PosReducer = {
	myPos: [] as PosReducer['myPos'],
	selectedPos: {} as PosReducer['selectedPos'],
	loading: false,
	hasDistributor: false,
	posRegisterData: null,
};

export default function posReducer(state = initialState, { type, payload }: any) {
	switch (type) {
		case 'SET_MY_POS': {
			const myPos = payload.myPos as PosReducer['myPos'];
			return { ...state, myPos };
		}

		case 'SET_SELECTED_POS': {
			const selectedPos = payload.pos as PosReducer['selectedPos'];

			return { ...state, selectedPos };
		}

		case 'SET_POS_DISTRIBUTORS': {
			const distributors = payload.distributors as Concrete<PosReducer['selectedPos']['distributors']>;
			return { ...state, selectedPos: { ...state.selectedPos, distributors } };
		}

		case 'SET_POS_LOADING': {
			const loading = payload.loading as PosReducer['loading'];
			return { ...state, loading };
		}

		case 'SET_CLEAN_POS': {
			return initialState;
		}

		case 'SET_UPDATE_DISTRIBUTOR': {
			return {
				...state,
				selectedPos: {
					...state.selectedPos,
					distributors: state.selectedPos.distributors?.map((distributor) => {
						if (distributor.id === payload.distributor.id) return payload.distributor;

						return distributor;
					}),
				},
			};
		}

		case 'SET_HAS_DISTRIBUTOR': {
			return { ...state, hasDistributor: payload.hasDistributor };
		}

		case 'MANAGE_POS_REGISTER_DATA':
			if (payload.action === 'SET') {
				return {
					...state,
					posRegisterData: payload.data || null,
				};
			}
			if (payload.action === 'CLEAR') {
				return {
					...state,
					posRegisterData: null,
				};
			}
			return state;

		default:
			return state;
	}
}
