import { Button, Flex, Icon, Select, Typography } from 'front-commons/ds';
import { useFetch } from 'front-commons/hooks';
import { useEffect, useMemo, useState } from 'react';
import DistributorsTabs from 'containers/DistributorsTabs';
import { CardsRenderProps } from 'containers/DistributorsTabs/interfaces';
import { selectedDistributorsHandler } from 'pages/pos/Distributors/helpers';
import {
	BusinessUnitResponseEnum,
	DistributorsSourceParams,
} from 'pages/pos/Distributors/interfaces';
import { PosListProps } from 'services/import/interfaces';
import { findDistributors, findSelectedDistributors, getMassiveDistributorsList } from 'services/pos';
import useDrawer from 'stores/drawer';
import CTA from './CTA';
import { onMultipleSelectStateHandler } from './helpers';
import { BUsToRenderParams, CTAProps, DistributorAttributingDrawerProps } from './interfaces';
import DrawerDistributorSkeleton from './Skeleton';

export default function DistributorAttributing({
	BUs,
	posList,
	onlyEmptyBUs = true,
	ignoreOption,
	posFiltersOptions,
	handleRefetchList,
	onFinalizeAttributing,
	setHasDistributorChanged,
}: DistributorAttributingDrawerProps) {
	//* =============== GENERAL LOGICS ===============
	const { handleCloseAllDrawers, isDrawerOpen } = useDrawer();

	const [selectedPos, setSelectedPos] = useState<PosListProps[]>([]);
	const [activeTab, setActiveTab] = useState('');
	const [businessUnitiesAvailable, setBusinessUnitiesAvailable] = useState<DistributorsSourceParams[]>([]);
	const [distributors, setDistributors] = useState<DistributorsSourceParams[]>([]);
	const [posFilter, setPosFilter] = useState<string[]>([]);

	const resetInitialValues = () => {
		setActiveTab('');
		setBusinessUnitiesAvailable([]);
		setDistributors([]);
	};

	const BUsToRender = useMemo(() => {
		return distributors.reduce((acc, curr, index, array) => {
			const selected = businessUnitiesAvailable?.find((dist) => curr.id === dist?.id);

			const anotherBUInfos =
				array.length > 1 && !index
					? {
							id: array[1].id,
							name: array[1].name,
							enabled: false,
					  }
					: undefined;

			acc[curr.id] = { ...curr, anotherBUInfos, selected };

			return acc;
		}, {} as BUsToRenderParams);
	}, [distributors, businessUnitiesAvailable, activeTab]);

	const activeTabData = BUsToRender?.[activeTab];

	// HANDLE WITH TITLE
	const handleGetTitle = () => {
		if (posFiltersOptions) return 'Alteração de distribuidores';

		const defaultBegin = 'Seleção de distribuidores para';

		if (selectedPos?.length === 1) {
			return `${defaultBegin} ${selectedPos[0].posName.toUpperCase()} - ${CNPJ(selectedPos[0].posCnpj).mask}`;
		}

		if (selectedPos) return `${defaultBegin} ${selectedPos.length} lojas selecionadas.`;

		return null;
	};

	const isOpen = isDrawerOpen('distributor-attributing');

	const currentSimplePosId = selectedPos?.[0]?.posId;

	const attributingType = () => {
		return selectedPos.length === 1 ? 'SIMPLE' : 'MASSIVE';
	};

	//* =============== SIMPLE LOGICS ===============

	// HANDLE GET SELECTED DISTRIBUTORS
	const { fetch: handleGetSelectedDistributors, loading: loadingSelected } = useFetch({
		fetchFunction: findSelectedDistributors,
		disabled: attributingType() !== 'SIMPLE',
		fetchParams: { posId: currentSimplePosId },
	});

	// HANDLE GET ALL DISTRIBUTORS
	const { fetch: handleGetAllDistributors, loading: loadingAll } = useFetch({
		fetchFunction: findDistributors,
		disabled: attributingType() !== 'SIMPLE',
		fetchParams: { posId: currentSimplePosId },
	});

	// HANDLE WITH SIMPLE DATA
	const handleGetBUsToSimple = async () => {
		const [selectedDistributors = [], allDistributors = []] = await Promise.all([
			handleGetSelectedDistributors(),
			handleGetAllDistributors(),
		]);

		allDistributors.forEach(({ distributors, ...rest }) => {
			if (selectedDistributors.find((dist) => dist.businessUnitId === rest.businessUnitId)) return;

			selectedDistributors.push({ ...rest, distributors: [] });
		});

		const eligibleBUs: DistributorsSourceParams[] =
			posFiltersOptions || !onlyEmptyBUs
				? selectedDistributors.map(({ businessUnitId, businessUnitName, ...rest }) => ({
						id: businessUnitId,
						name: businessUnitName,
						type: 'businessUnit',
						...rest,
				  }))
				: selectedDistributors.reduce((acc, BU) => {
						if (!BU.distributors.length)
							acc.push({
								id: BU.businessUnitId,
								name: BU.businessUnitName,
								type: 'businessUnit',
								...BU,
							});

						return acc;
				  }, [] as DistributorsSourceParams[]);

		const allDistributorsOfUnattributedBUs = allDistributors.reduce((acc, dist) => {
			if (eligibleBUs.find(({ id }) => dist[BusinessUnitResponseEnum.BusinessUnitId] === id)) {
				acc.push({
					id: dist[BusinessUnitResponseEnum.BusinessUnitId],
					name: dist[BusinessUnitResponseEnum.BusinessUnitName],
					type: 'businessUnit',
					...dist,
				});
			}

			return acc;
		}, [] as DistributorsSourceParams[]);

		setDistributors(allDistributorsOfUnattributedBUs);

		setBusinessUnitiesAvailable(selectedDistributorsHandler(eligibleBUs, allDistributorsOfUnattributedBUs));
		setActiveTab(allDistributorsOfUnattributedBUs?.[0]?.id);
	};

	//* =============== MASSIVE LOGICS ===============
	const [conflicts, setConflicts] = useState<Record<string, Record<string, string>>>();

	// HANDLE WITH MASSIVE DATA
	const { fetch: handleGetBUsToMassive, loading: loadingMassive } = useFetch({
		fetchFunction: getMassiveDistributorsList,

		disabled: !selectedPos?.length,
		fetchParams: {
			region: selectedPos.reduce(
				(acc, { region }) => (acc?.includes(region) ? acc : [...(acc || []), region]),
				null as any as string[],
			),
			pointOfSale: selectedPos.map(({ posId }) => posId),
		},

		onSuccess(response) {
			if (!response || !BUs) return;

			const hasData = !!response.businessUnitDistributors.length;

			const parsedData = {
				placeholder: Object.entries(BUs).map<DistributorsSourceParams>(([id, name]) => ({
					id,
					name,
					distributors: [],
					pedEnabled: false,
					type: 'businessUnit',
				})),
				real: {
					distributors: response.businessUnitDistributors.map<DistributorsSourceParams>(({ businessUnitId, distributorList }) => ({
						id: businessUnitId,
						name: BUs[businessUnitId],
						distributors: distributorList as any as DistributorsSourceParams['distributors'],
						pedEnabled: false,
						type: 'businessUnit',
					})),
					BUsAvailable: response.businessUnitDistributors.map<DistributorsSourceParams>(({ businessUnitId }) => ({
						id: businessUnitId,
						name: BUs[businessUnitId],
						distributors: [] as DistributorsSourceParams['distributors'],
						pedEnabled: false,
						type: 'businessUnit',
					})),
				},
			};

			setDistributors(hasData ? parsedData.real.distributors : parsedData.placeholder);
			setBusinessUnitiesAvailable(hasData ? parsedData.real.BUsAvailable : parsedData.placeholder);
			setActiveTab(hasData ? parsedData.real.distributors[0].id : parsedData.placeholder[0].id);

			const busStructure = response.businessUnitDistributors.reduce((acc, { businessUnitId }) => {
				return {
					...acc,
					[businessUnitId]: {},
				};
			}, {}) as Record<string, Record<string, string>>;

			const allBus = Object.keys(busStructure);

			response.pointOfSaleList.forEach(({ businessUnities, pedEnabledForBusinessUnities, cnpj, tradeName }) => {
				const label = `${CNPJ(cnpj).mask} ${tradeName}`;

				(pedEnabledForBusinessUnities || []).forEach((buId) => {
					busStructure[buId] = {
						...busStructure[buId],
						[label]: 'PED',
					};
				});

				allBus.forEach((buId) => {
					if (!businessUnities.includes(buId)) {
						busStructure[buId] = {
							...busStructure[buId],
							[label]: 'UNTENDED',
						};
					}
				});
			});

			setConflicts(busStructure);
		},
	});

	//* =============== GENERAL LOGICS ===============

	// MOCKING LOADER HANDLER
	const handleLoading = () => ({
		get() {
			return loadingSelected || loadingAll || loadingMassive;
		},
	});

	const handleSetFilteredPos = () => {
		resetInitialValues();
		const newSelectedPos = posFiltersOptions?.filter((item) => posFilter.includes(item.posId));
		if (newSelectedPos) setSelectedPos(newSelectedPos);
	};

	const hasFilterChanged = useMemo(() => {
		if (selectedPos.length !== posFilter.length) return true;

		const selectedPosId = selectedPos.map(({ posId }) => posId);

		return !posFilter.every((posId) => selectedPosId.includes(posId));
	}, [selectedPos, posFilter]);

	const canShowDistributorsPanel = () => {
		if (posFiltersOptions) return activeTabData && !handleLoading().get() && !!selectedPos.length && !hasFilterChanged;

		return activeTabData && !handleLoading().get() && !!selectedPos.length;
	};

	useEffect(() => {
		if (attributingType() === 'SIMPLE') handleGetBUsToSimple();
		if (attributingType() === 'MASSIVE') handleGetBUsToMassive();
	}, [selectedPos]);

	useEffect(() => {
		if (!isOpen) {
			setSelectedPos([]);
			setPosFilter([]);
			resetInitialValues();
		}

		if (!posFiltersOptions && isOpen) setSelectedPos(posList);
	}, [isOpen]);

	return (
		<Flex padding="40px 24px" direction="column" overflow="auto" height="100%">
			<Flex
				padding="0 0 24px 0"
				borderWidth="0 0 1px 0"
				borderStyle="solid"
				borderColor="--border-primary"
				alignItems="center"
				width="100%"
				justifyContent="space-between"
			>
				<Typography variant="Subtitle">{handleGetTitle()}</Typography>
				<Button variant="none" onClick={handleCloseAllDrawers}>
					<Icon name="close" />
				</Button>
			</Flex>

			{posFiltersOptions && (
				<Flex>
					<Select
						name="pos"
						onChange={({ currentTarget: { value } }, method) => {
							onMultipleSelectStateHandler(setPosFilter, {
								value,
								method,
								allValueOptions: posFiltersOptions.map(({ posId }) => posId),
							});
						}}
						value={posFilter}
						options={posFiltersOptions.map(({ posId, posCnpj, posName }) => ({
							label: `${CNPJ(posCnpj).mask} ${posName}`,
							value: posId,
						}))}
						width="100%"
						isMulti
						searchable
						massiveSelectOption
						massiveLabel="Selecionar todas"
						// eslint-disable-next-line react/no-unstable-nested-components
						selectedValuesRender={(selected) => {
							if (selected.length === 1) {
								const posData = posFiltersOptions.find(({ posId }) => posId === selected[0]);

								if (!posData) return null;

								return (
									<Typography title={posData.posName}>
										<strong>{CNPJ(posData.posCnpj).mask}</strong> {posData.posName}
									</Typography>
								);
							}

							const allPosSelected = posFiltersOptions.length === selected.length;

							if (selected.length)
								return (
									<Typography title={`${allPosSelected ? 'Todas' : posFilter.length} lojas selecionadas`}>
										<strong>{allPosSelected ? 'Todas' : posFilter.length} lojas</strong> selecionadas
									</Typography>
								);

							return null;
						}}
					/>

					<Button onClick={handleSetFilteredPos} disabled={!posFilter.length || !hasFilterChanged}>
						Buscar distribuidores
					</Button>
				</Flex>
			)}

			{handleLoading().get() && <DrawerDistributorSkeleton />}

			{canShowDistributorsPanel() && (
				<Flex overflow="auto" width="100%" maxWidth="592px" padding="0 16px 0 0">
					<DistributorsTabs
						isDrawer
						type={attributingType()!}
						posList={selectedPos?.map(({ posId }) => posId)}
						conflicts={conflicts?.[activeTabData.id]}
						sourceId={activeTabData.id}
						pharmacyId={currentSimplePosId as CardsRenderProps['pharmacyId']}
						available={activeTabData}
						selected={activeTabData.selected}
						setSelected={setBusinessUnitiesAvailable}
						handleRefetchSelectedDistributors={handleGetBUsToSimple}
						handleLoading={handleLoading as any}
						validateDistributors={() => true}
						onAfterSimpleAdd={() => {
							if (activeTabData.anotherBUInfos || !onFinalizeAttributing) return;
							onFinalizeAttributing(selectedPos.map(({ posId }) => posId));
						}}
						onAfterSimpleRemove={(selected) => {
							if (
								activeTabData.anotherBUInfos ||
								!onFinalizeAttributing ||
								(selected?.[BusinessUnitResponseEnum.Distributors].length || 0) > 1
							) {
								return;
							}

							onFinalizeAttributing(
								selectedPos.map(({ posId }) => posId),
								false,
							);
						}}
						CustomCTA={
							<CTA
								type={attributingType()!}
								selectedPos={selectedPos}
								activeTab={activeTab}
								ignoreOption={ignoreOption}
								BUsToRender={BUsToRender}
								setActiveTab={setActiveTab}
								anotherBUInfos={activeTabData.anotherBUInfos as CTAProps['anotherBUInfos']}
								handleCloseDrawer={handleCloseAllDrawers}
								handleRefetchList={handleRefetchList}
								onFinalizeAttributing={onFinalizeAttributing}
								setHasDistributorChanged={setHasDistributorChanged}
							/>
						}
					/>
				</Flex>
			)}
		</Flex>
	);
}
