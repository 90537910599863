import { DistributorProps, DistributorsSourceParams } from 'pages/pos/Distributors/interfaces';
import { CardsRenderProps } from './interfaces';

export const handleAddDistributor = (
	prevState: DistributorsSourceParams[],
	available: CardsRenderProps['available'],
	sourceId: CardsRenderProps['sourceId'],
	distributorId: string,
	indexPosition: number | undefined,
	temp: DistributorsSourceParams['distributors'],
) => {
	const BUs = prevState.reduce(
		(acc, BU) => ({ ...acc, [BU?.id]: BU }),
		{} as GenericObject<string, DistributorsSourceParams>,
	);

	const newDistributor = available.distributors?.find((distributor) => distributor.id === distributorId)!;
	const currentDistributors = BUs[sourceId]?.distributors?.length
		? BUs[sourceId].distributors
		: ([null] as any as DistributorProps[]);

	const distributorsWithNewDistributor = currentDistributors.reduce<DistributorProps[]>(
		(acc, curr, index, { length }) => {
			if (curr === null) return [{ ...newDistributor, order: 1 }];

			const isOnLastPosition = index + 1 === length;

			if (indexPosition === undefined) {
				if (isOnLastPosition) return [...acc, curr, { ...newDistributor, order: length + 1 }];
				return [...acc, curr];
			}

			const newDistributorPosition = indexPosition + 1;
			const currentPosition = index + 1;

			const isOnNewDistributorPosition = currentPosition === newDistributorPosition;
			const hasPassedNewDistributor = currentPosition > newDistributorPosition;
			const addedAtLastPosition = length === indexPosition;

			if (isOnNewDistributorPosition) {
				return [
					...acc,
					{ ...newDistributor, order: newDistributorPosition },
					{ ...curr, order: newDistributorPosition + 1 },
				];
			}

			if (hasPassedNewDistributor) return [...acc, { ...curr, order: currentPosition + 1 }];

			if (addedAtLastPosition && isOnLastPosition) {
				return [...acc, curr, { ...newDistributor, order: newDistributorPosition }];
			}

			return [...acc, curr];
		},
		[],
	);

	temp.push(...distributorsWithNewDistributor);

	const addedCard = {
		...BUs,
		[sourceId]: {
			...BUs[sourceId],
			distributors: distributorsWithNewDistributor,
		},
	};

	return Object.values(addedCard);
};

export const handleRemoveDistributor = (
	prevState: DistributorsSourceParams[],
	sourceId: CardsRenderProps['sourceId'],
	distributorId: string,
): DistributorsSourceParams[] => {
	return prevState.map((BU) => {
		if (BU.id !== sourceId) return BU;

		return {
			...BU,
			distributors: [...BU.distributors.filter((distributor) => distributor.id !== distributorId)],
		};
	});
};

export const handleRePositionDistributor = (
	prevState: DistributorsSourceParams[],
	sourceId: CardsRenderProps['sourceId'],
): DistributorsSourceParams[] => {
	return prevState.map((BU) => {
		if (BU.id !== sourceId) return BU;

		return {
			...BU,
			distributors: [...BU.distributors.map((distributor, index) => ({ ...distributor, order: index + 1 }))],
		};
	});
};

export const handleReorderDistributor = (
	prevState: DistributorsSourceParams[],
	sourceId: CardsRenderProps['sourceId'],
	from: number,
	to: number,
	temp: DistributorsSourceParams['distributors'],
) => {
	const BUs = prevState.reduce(
		(acc, BU) => ({ ...acc, [BU.id]: BU }),
		{} as GenericObject<string, DistributorsSourceParams>,
	);

	const currentDistributors = BUs[sourceId].distributors.length
		? BUs[sourceId].distributors
		: ([null] as any as DistributorProps[]);

	const reordered = currentDistributors.changePosition<DistributorProps>(from, to);

	const reOrderedDistributors = reordered.map((distributor, idx) => ({
		...distributor,
		order: idx + 1,
	}));

	temp.push(...reOrderedDistributors);

	const addedCard = {
		...BUs,
		[sourceId]: {
			...BUs[sourceId],
			distributors: reOrderedDistributors,
		},
	};

	return Object.values(addedCard);
};
