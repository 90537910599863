import styled from "styled-components";
import * as Accordion from '@radix-ui/react-accordion'
import { Flex, getThemeAnimation } from "front-commons/ds";

const accordionAnimation = getThemeAnimation().accordion.openClose()
const { Parent: chevronAnimation } = getThemeAnimation().chevron.rotate(
	'i[data-testid*="expand"]',
	'data-state',
	{
		initial: 'closed',
		final: 'open'
	},
	'180'
);

export const Container = styled(Flex).attrs({
	"data-testid": 'side-basket-Source-card-Container',
	as: Accordion.Root,
	type: "multiple"
})`${accordionAnimation.Root}`;

export const Trigger = styled(Flex).attrs({
	"data-testid": 'side-basket-Source-card-Trigger',
	as: Accordion.Trigger
})`${chevronAnimation}`;

export const Content = styled(Flex).attrs({
	"data-testid": 'side-basket-Source-card-Content',
	as: Accordion.Content
})`${accordionAnimation.Content}`;

export const Item = styled(Flex).attrs({
	"data-testid": 'side-basket-Source-card-Item',
	as: Accordion.Item
}) <{ value: string }>``;