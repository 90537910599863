import { Flex, Button, Typography, notify } from 'front-commons/ds';
import { useFetch } from 'front-commons/hooks';
import { useState } from 'react';
import { BusinessUnitResponseEnum } from 'pages/pos/Distributors/interfaces';
import { getMassiveProcessingStatus, saveMassiveDistributors } from 'services/pos';
import { updateLoopingProviderGTM } from 'shared/gtm';
import useDialog from 'stores/dialog';
import { DistributorsCallToActionProps } from './interfaces';

export default function ColumnsCTA({
	buId,
	type,
	posList,
	currentBUName,
	loadingPharmacy,
	selectedDistributors,
	hasSelectedDistributorsChanged,
	handleClickMakeOrder,
	setLastDistributorConfigSaved,
}: DistributorsCallToActionProps) {
	const [processing, setProcessing] = useState(false);
	const { handlePreventDataLoss } = useDialog();

	const { fetch: getProcessingStatus } = useFetch({
		fetchFunction: getMassiveProcessingStatus,
		recursive: true,
		recursiveTime: 1000,

		recursiveTill(response) {
			return response?.status === 'PROCESSED';
		},

		onRecursiveStops(response) {
			setProcessing(false);

			if (response?.status === 'PROCESSED') {
				handlePreventDataLoss.disable();
				notify.positive({
					description: (
						<Typography>
							Suas alterações de <strong>{currentBUName}</strong> foram salvas.
						</Typography>
					),
				});
			}
		},

		onBeforeFetch() {
			setProcessing(true);
			handlePreventDataLoss.enable();
		},

		onError() {
			notify.negative({ description: 'Erro ao buscar o status do processamento.' });
			setProcessing(false);
		},
	});

	const { fetch: fetchSaveDistributors, loading } = useFetch({
		fetchFunction: saveMassiveDistributors,
		onSuccess(response) {
			if (!response) return;

			getProcessingStatus({ loopingProcessingId: response.loopingProcessingId });
		},

		onError() {
			notify.negative({ description: 'Erro ao salvar os distribuidores.' });
		},
	});

	const handleSaveMassiveDistributors = () => {
		if (!selectedDistributors?.[BusinessUnitResponseEnum.Distributors].length || !hasSelectedDistributorsChanged) {
			notify.negative({
				title: 'Sua lista de distribuidores está vazia.',
				description: 'Por favor selecione no mínimo 1 distribuidor para visualizar os produtos na loja.',
			});

			return;
		}

		selectedDistributors?.[BusinessUnitResponseEnum.Distributors].forEach((distributor, index) => {
			updateLoopingProviderGTM('massive_looping_provider', {
				distributorName: distributor.summarizedCorporateName,
				businessUnitName: selectedDistributors.id,
				index,
				minimumValue: 0,
			});
		});

		setLastDistributorConfigSaved?.((prevState) => ({
			...prevState,
			[buId!]: selectedDistributors?.[BusinessUnitResponseEnum.Distributors]!,
		}));

		fetchSaveDistributors({
			// @ts-ignore
			[`${selectedDistributors.type}Id`]: buId,
			pointOfSaleIdList: posList,
			distributorList: selectedDistributors?.[BusinessUnitResponseEnum.Distributors].map((distributor, index) => ({
				distributorId: distributor.id,
				order: index + 1,
			})),
		});
	};

	if (type === 'MASSIVE')
		return (
			<Flex width="100%" justifyContent="end">
				<Button
					size="large"
					width={{ small: '100%', medium: 'fit-content' }}
					loading={loading || processing}
					onClick={handleSaveMassiveDistributors}
				>
					Salvar distribuidores
				</Button>
			</Flex>
		);

	return (
		<Flex width="100%" justifyContent="end">
			<Button
				onClick={handleClickMakeOrder}
				loading={!!loadingPharmacy}
				variant="primary"
				size="large"
				isFullWidth
				maxWidth={{ small: 'none', medium: '264px' }}
			>
				Ir para loja
			</Button>
		</Flex>
	);
}
