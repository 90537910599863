import { Flex, Icon, Typography } from 'front-commons/ds';
import { Container, Content, Item, Trigger } from '../SourceCard/styles';
import ValuesSummary from 'containers/Summaries/ValuesSummary';
import { BasketSummaryProps } from 'containers/Summaries/ValuesSummary/interfaces';

export default function CartTotals({
	brute,
	discount,
	liquid,
	tax,
}: Pick<BasketSummaryProps, 'brute' | 'liquid' | 'discount' | 'tax'>) {
	return (
		<Flex direction="column" gap="8px">
			<Container data-testid="side-basket-Footer-Cart-totals-Container">
				<Item
					gap="0"
					value="cart-totals"
					data-testid="side-basket-Footer-Cart-totals-Item"
					width="100%"
					direction="column"
				>
					<Trigger
						data-testid="side-basket-Footer-Cart-totals-Trigger"
						gap="4px"
						alignItems="center"
						cursor="pointer"
						width="100%"
						padding="0"
					>
						<Typography color="--text-link" variant="ParagraphSmall/Semibold">
							Resumo do pedido
						</Typography>

						<Icon name="expand_less" size="16px" color="--text-link" weight={600} margin="2px 0 0" />
					</Trigger>

					<Content gap="8px" data-testid="side-basket-Footer-Cart-totals-Content" direction="column">
						<Flex padding="16px 0 0">
							<ValuesSummary discount={discount} brute={brute} tax={tax} hideValue={{ total: true }} />
						</Flex>
					</Content>
				</Item>
			</Container>

			<Flex
				padding="8px 0 0"
				border="1px solid"
				borderWidth="1px 0 0"
				borderColor="--border-primary"
				justifyContent="space-between"
				alignItems="center"
			>
				<Typography>Total</Typography>
				<Flex style={{ fontSize: 18, fontWeight: 600 }} as={Typography}>
					{liquid.toCurrency()}
				</Flex>
			</Flex>
		</Flex>
	);
}
