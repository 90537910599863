import { Flex, getThemeMedia, getThemeSpace } from 'front-commons/ds';
import { DraggableStateSnapshot } from 'react-beautiful-dnd';
import styled, { css } from 'styled-components';

interface ContainerProps extends Partial<DraggableStateSnapshot> {
	isSelected?: boolean;
	disabled?: boolean;
	animation?: boolean;
	index?: number;
}

export const Container = styled.div<ContainerProps>`
	display: flex;
	position: relative;
	align-items: center;
	justify-content: space-between;

	background-color: var(--surface-white);
	border: 2px solid var(${({ isSelected }) => (isSelected ? '--border-active' : '--border-primary')});
	border-radius: 8px;
	padding: ${getThemeSpace('16')};

	margin-top: ${({ isDragging }) => (isDragging ? -12 : 0)}px;
	max-height: 80px;
	height: 80px;
	width: 100%;

	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'default')};
	overflow: hidden;
	transition: margin 200ms ease-in-out;
	user-select: none;

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			max-height: 77px;
			height: 77px;
		}
	}

	${({ animation, index = 0 }) =>
		animation &&
		css`
			border: 2px solid var(--border-active);

			transform: translateY(-${!index ? 0 : 88 * index + 88}px);
			transition: all 300ms ease-in-out;
			z-index: 3;
		`}

	${({ isDragging, isSelected }) =>
		isDragging &&
		!isSelected &&
		css`
			margin-top: 6px;
		`}

	.remove-style {
		min-height: fit-content;
		min-width: fit-content;
		padding: 0;

		&:disabled {
			background: none;

			cursor: not-allowed;
		}
	}
`;

export const NameWrapper = styled(Flex)`
	span {
		width: auto;
		overflow: hidden;

		white-space: nowrap;
		text-overflow: ellipsis;

		@supports (-webkit-line-clamp: 2) {
			@media screen {
				@media (max-width: ${getThemeMedia('medium')}) {
					display: -webkit-box;
					white-space: normal;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}
			}
		}
	}
`;

export const ButtonOrderWrapper = styled.div`
	display: flex;
	flex-shrink: 0;
	flex-direction: column;
	gap: 16px;
	padding-top: 32px;
	padding-bottom: 32px;

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			display: none;
		}
	}
`;

export const ButtonSelectWrapper = styled.div`
	display: none;

	rotate: 90deg;

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			display: initial;
		}
	}
`;

export const DummyContainer = styled(Container)<{ isVisible: boolean }>`
	height: 77px;

	background-color: var(--surface-primary);
	border-style: dashed;

	opacity: 1;
	transition: opacity 200ms ease-in-out;

	${({ isVisible }) =>
		!isVisible &&
		css`
			transition: none;
			opacity: 0;
		`}
`;
