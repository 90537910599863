import { Typography } from 'front-commons/ds';
import { DistributorsFiltersExhibitionType, FindPharmaciesResponse } from 'services/pos/interfaces';
import { PosParams } from 'stores/pos/interfaces';
import { DistributorProps, DistributorsSourceParams } from './interfaces';
import { getIndustriesAvailableDistributors, getIndustriesSelectedDistributors } from 'services/pos';

export const selectedDistributorsHandler = (
	selectedDistributors: DistributorsSourceParams[],
	availableDistributors: DistributorsSourceParams[],
) => {
	if (selectedDistributors.length === availableDistributors.length) return selectedDistributors;

	return availableDistributors.map(({ id, type }) => {
		const distributorTurn = selectedDistributors.find((distributor) => distributor.id === id);
		if (distributorTurn) return distributorTurn;

		return {
			id,
			type: type || 'industry',
			distributors: undefined as any as DistributorsSourceParams['distributors'],
		};
	}) as DistributorsSourceParams[];
};

export const regionsLabel: Record<UFAcronym, string> = {
	AC: 'Acre',
	AL: 'Alagoas',
	AP: 'Amapá',
	AM: 'Amazonas',
	BA: 'Bahia',
	CE: 'Ceará',
	DF: 'Distrito Federal',
	ES: 'Espírito Santo',
	GO: 'Goiás',
	MA: 'Maranhão',
	MT: 'Mato Grosso',
	MS: 'Mato Grosso do Sul',
	MG: 'Minas Gerais',
	PA: 'Pará',
	PB: 'Paraíba',
	PR: 'Paraná',
	PE: 'Pernambuco',
	PI: 'Piauí',
	RJ: 'Rio de Janeiro',
	RN: 'Rio Grande do Norte',
	RS: 'Rio Grande do Sul',
	RO: 'Rondônia',
	RR: 'Roraima',
	SC: 'Santa Catarina',
	SP: 'São Paulo',
	SE: 'Sergipe',
	TO: 'Tocantins',
};

export const getSimpleSelectedPosData = (posList: FindPharmaciesResponse[] | PosParams[], posId: string) => {
	const posData = posList?.find(({ pointOfSaleId }) => pointOfSaleId === posId);

	if (!posData) return undefined;

	return (
		<Typography title={posData.tradeName}>
			{CNPJ(posData.cnpj).mask} <strong>{posData.tradeName}</strong>
		</Typography>
	);
};

export const getFiltersColumnSize = (
	view: DistributorsFiltersExhibitionType | undefined,
	simulatedViewType: boolean,
	hasFlags: boolean,
) => {
	if (view === 'SIMPLE' || simulatedViewType) return '376px';

	return hasFlags ? 'repeat(2, 252px) 376px' : '252px 376px';
};

export const maybeArrayToString = (data: string[] | string | undefined = '') => (Array.isArray(data) ? data[0] : data);

export function getIndustryDistributors(
	posId: POSEntity['id'],
	industryId: IndustryEntity['industryId'],
) {
	let industriesDistributors: DistributorProps[] = [];

	const getData = async (cb: (props: any) => any) => {
		const data = await cb({ posId, industryId });

		industriesDistributors = data.distributors;
	};

	return {
		async available() {
			await getData(getIndustriesAvailableDistributors);

			return industriesDistributors;
		},

		async selected() {
			await getData(getIndustriesSelectedDistributors);

			return industriesDistributors;
		},

		async all() {
			const available = await getIndustryDistributors(posId, industryId).available();
			const selected = await getIndustryDistributors(posId, industryId).selected();

			return { available, selected };
		},
	};
}
