import { Flex, Typography } from 'front-commons/ds';
import { useEffect, useState } from 'react';
import { Draggable, DroppableProvided } from 'react-beautiful-dnd';
import DistributorCard from 'containers/Cards/DistributorCard';
import ColumnsCTA from './CTA';
import DummyCards from './DummyCards';
import { DistributorsAvailableProps } from './interfaces';
import { AvailableDropContainer } from './styles';

export default function AvailableColumn({
	buId,
	isPed,
	filter,
	posList,
	isTablet,
	selected,
	CustomCTA,
	available,
	pharmacyId,
	hideMinValue,
	dropProvided,
	hasPermission,
	applyMinHeight,
	filterComponent,
	callToActionProps,
	selectedDistributors,
	handleAdd,
}: DistributorsAvailableProps & { dropProvided: DroppableProvided }) {
	const [animate, setAnimate] = useState(false);

	const maxOfSelected = (selected?.distributors?.length || 0) === 5;
	const parsedAvailable = available?.distributors
		.filter(({ id }) => !selected?.distributors?.find((myDistributor) => myDistributor.id === id))
		.filter(({ summarizedCorporateName }) => summarizedCorporateName.toLowerCase().includes(filter.toLowerCase()));

	const getPedMinHeight = () => {
		if (applyMinHeight) return '72px';
		return '0px';
	};

	const getPedDummyMarginTop = () => {
		if (!parsedAvailable.length) return '-24px';
		return parsedAvailable.length || applyMinHeight ? undefined : '-32px';
	};

	useEffect(() => {
		if (animate) setTimeout(() => setAnimate(false), 300);
	}, [animate]);

	return (
		<Flex direction="column" gap="24px" minWidth={{ medium: 'calc(50% - 32px)' }} order={{ small: 2, medium: 1 }}>
			<Flex justifyContent="center" textAlign="center" display={{ small: 'none', medium: 'flex' }}>
				<Typography variant="Paragraph/Semibold">Distribuidores disponíveis</Typography>
			</Flex>

			{isTablet && filterComponent}

			<AvailableDropContainer
				direction="column"
				gap="8px"
				ref={dropProvided.innerRef}
				{...dropProvided.droppableProps}
				onClick={(el) => {
					// @ts-ignore
					const index = Array.from(el.target.attributes).filter(({ name }) => name === 'data-index')[0]?.value || 0;
					if (Number(index) && hasPermission) setAnimate(true);
				}}
				animate={animate}
				minHeight={{ medium: isPed ? getPedMinHeight() : '610px' }}
			>
				{parsedAvailable.length === 0 && <Typography />}
				{parsedAvailable?.map((props, index) => (
					<Draggable
						key={props.id}
						draggableId={props.id}
						index={index}
						data-test-id={`drag-element-available-${props.id}`}
						isDragDisabled={maxOfSelected}
					>
						{(dragProvided, dragSnapshot) => (
							<DistributorCard
								active={{
									...props,
									index,
									order: undefined,
									isTablet,
									disabled: maxOfSelected,
									hideOrder: true,
									pharmacyId,
									hideMinValue,
									dragSnapshot,
									dragProvided,
									hasPermission,
									onAdd: handleAdd,
								}}
							/>
						)}
					</Draggable>
				))}
			</AvailableDropContainer>
			{isPed && (
				<DummyCards
					type="BLURRED"
					marginTop={getPedDummyMarginTop()}
					hasPermission={hasPermission}
					isTablet={isTablet}
				/>
			)}
			{!CustomCTA && (
				<Flex display={{ small: 'flex', medium: 'none' }}>
					<ColumnsCTA
						{...callToActionProps}
						selectedDistributors={selectedDistributors}
						posList={posList}
						buId={buId}
					/>
				</Flex>
			)}
		</Flex>
	);
}
