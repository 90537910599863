import { ProductCard as SideBasketProductCard } from 'front-commons/ds';
import { createElement, useEffect, useState } from 'react';
import { ProductCardProps } from '../../interfaces';
import { AddToBasketButtonProps } from 'containers/AddToBasketButton/interfaces';
import { SuggestedProps } from 'services/basket/interfaces';
import { SideBasketProps } from 'front-commons/ds/components/ProductCard/variants/SideBasket/interfaces';
import { updateCartGTM } from 'shared/gtm';
import AddToBasketButton from 'containers/AddToBasketButton';

export default function ProductCard({
	data: {
		id,
		ean13,
		valid,
		imageURL: simpleImageURL,
		quantity: initialQuantity,
		brandName,
		hyperaCode,
		productType,
		description,
		unitDiscount,
		invalidReason,
		finalTotalPrice,
		productsInCombo,
		suggestionGroupId,
		unitPricePromotion,
		suggestionGroupName,
	},
	sourceData: { businessUnitId, industryId, industryName, businessUnitName, distributorId, distributorName },
	posId,
	loadingBasketType,
	deletingProductId,
	hasEditBasketPermission,
	handleOpenDrawer,
	handleRemoveFromBasket,
}: ProductCardProps) {
	const [quantity, setQuantity] = useState<number>(initialQuantity);
	const [loadingType, setLoadingType] = useState<SideBasketProps['loadingType']>();

	const ean = productType === 'COMBO' && productsInCombo.length === 1 ? productsInCombo[0].ean13 : ean13;
	const imageURL = simpleImageURL || productsInCombo?.[0]?.imageURL || '';

	const notValid = valid === false || !!invalidReason;

	const counterOptions: AddToBasketButtonProps & Partial<SuggestedProps> = {
		buId: businessUnitId || industryId!,
		disabled: !!deletingProductId || loadingBasketType === 'refetch' || productType === 'COMBO' || notValid,
		productId: id,
		hasPermission: hasEditBasketPermission,
		distributorId: distributorId,
		counterOptions: {
			width: '99px',
			height: '37px',
			fontSize: '14px',
			disabled: notValid,
			iconProps: { size: '16px', color: '--text-link' },
			hasPermission: hasEditBasketPermission && !hyperaCode && !notValid,
		},
		initialQuantity: quantity,
		suggestionGroupId,
		suggestionGroupName,

		onItemChange: setQuantity,
		onUpdateBasketType(type: string, newQuantity: number) {
			updateCartGTM(type, unitPricePromotion * (newQuantity || quantity), [
				{
					ean13,
					description,
					price: unitPricePromotion,
					quantity: newQuantity || quantity,
					brandName,
					businessUnitName: businessUnitName || industryName!,
					discount: unitDiscount,
					affiliation: distributorName,
					combo: false,
				},
			]);
		},
	};

	const productCardProps: SideBasketProps = {
		ean,
		name: description,
		price: finalTotalPrice,
		imageURL,
		isInvalid: notValid,
		hyperaCode,
		loadingType,
		staticStorageURL: import.meta.env.VITE_STATIC_STORAGE_BASE,
		counterComponent: createElement(AddToBasketButton, counterOptions),
		hasEditBasketPermission,
		onRemoveClick() {
			handleRemoveFromBasket(id);
		},
		onEditPromotionClick() {
			handleOpenDrawer('promotion-details-drawer', {
				posId,
				promotionId: id,
			});
		},
	};

	useEffect(() => {
		if (deletingProductId === id) {
			setLoadingType('DELETING');
			return;
		}

		if (deletingProductId || loadingBasketType) {
			setLoadingType('WAITING');
			return;
		}

		setLoadingType(undefined);
	}, [deletingProductId, loadingBasketType]);

	return <SideBasketProductCard key={id} variant="SIDE_BASKET" {...productCardProps} />;
}
