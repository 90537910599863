import { AxiosError, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { notify } from 'front-commons/ds';
import { handleSetLogout } from 'stores/customer/middleware';
import { getStore } from 'stores/helpers';
import { store } from 'stores/store';
import { CreateAPIConnectionCbProps, HyperaFrontMocks, RequestHandlerOptionsParams, ResponseParams } from './interfaces';
import versions from '../version'
const mockFile = import.meta.glob('/notes/mocks.json');


const getGAHeaders = () => {
	if (!JSON.parse(import.meta.env.VITE_GA_HEADERS_SEND || 'false')) return null;

	try {
		// @ts-expect-error
		const getCookie = (regex: RegExp) => document?.cookie.match(regex)[1].split('.');

		const [[ga_session_id], [ga_client_id]] = [
			getCookie(/_ga_\w+=GS\d\.\d\.(.+?)(?:;|$)/),
			getCookie(/_ga=GA\d\.\d\.(.+?)(?:;|$)/),
		];

		return { ga_session_id, ga_client_id };
	} catch {
		return null;
	}
};

const mocksHandler = async (config: AxiosRequestConfig<any>) => {
	const mockFile: HyperaFrontMocks = await (async () => {
		const response = await fetch('/notes/mocks.json');
		const data = await response.json();
		return data;
	})()

	const regex = RegExp(
		config?.url!
			.replace(/\//g, '\\/')
			.replace(/[0-9a-fA-F]{24}/g, ':\\w+')
			.replace(/^/, '^')
			.replace(/$/, '$')
	);

	const mock = mockFile.mocks.find(({ url }) => regex.test(url));

	if (!mock || mock?.method !== config.method?.toUpperCase()) return;

	config.baseURL = import.meta.env.VITE_API_BASE_MOCK;

	const queryParams = (() => {
		const values = config?.url!.match(/[0-9a-fA-F]{24}/g);
		const keys = mock.url.match(/:\w+/g) as string[];

		return keys?.reduce((acc, key, index) => {
			if (values?.[index]) acc[key.slice(1)] = values[index];
			return acc;
		}, {} as Record<string, string>);
	})()

	const condition = mock?.conditions?.find(({ type, key, value }) => {
		if (type === 'query') {
			const target = queryParams[key];
			if (target === value) return true;
		}

		return false;
	})

	const mockHeaders = {
		'x-front-mock': JSON.stringify({
			matches: {
				reference: condition?.reference || mock?.reference,
				method: mock.method,
			},
			configs: {
				delay: mock?.delay ?? mockFile?.delay,
				replace: condition?.replace || mock?.replace,
			}
		}),
	}

	Object.assign(config?.headers || {}, mockHeaders);
}

export const handleRequest = async (
	config: AxiosRequestConfig<any>,
	callback?: CreateAPIConnectionCbProps['onRequest'],
): Promise<any> => {
	const gaHeaders = getGAHeaders();
	const Authorization = getStore().customerReducer.token;

	Object.assign(config?.headers || {}, { 'X-Front-Version': versions.frontVersion || 'not-set' });
	if (Authorization) Object.assign(config?.headers || {}, { Authorization });
	if (gaHeaders) Object.assign(config?.headers || {}, { ...gaHeaders });

	// @ts-expect-error
	const callbackConfig = callback?.(config) || {};

	if (!Object.isEmpty(mockFile)) await mocksHandler(config);

	return { ...config, ...callbackConfig };
};

export const handleReject = ({ response }: any) => {
	const handleUnauthorizeUser = ({ alert = false } = {}) => {
		if (alert) {
			notify.negative({ description: 'Sessão expirada! Por favor, faça login novamente para continuar.' });
		}
		store.dispatch(handleSetLogout());
	};

	if (response.status >= 400 && response.status < 500) {
		if (response.status === 401 || response.data.error === 'Denied') handleUnauthorizeUser();
		if (
			response.status === 403 &&
			response.data.error === 'Acesso negado.' &&
			getStore().customerReducer.data.accessType
		) {
			notify.negative({ description: 'Esse é um usuário simulado. Você não pode realizar essa ação.' });
		}

		throw new AxiosError('axios error', 'error', {} as any, {}, response);
	}

	if (response.status === 500 && response.data.error === 'Denied') handleUnauthorizeUser({ alert: true });

	notify.negative({ description: 'Ocorreu um erro com a requisição.' });
	throw new AxiosError('axios error', 'error', {} as any, {}, response);
};

export async function requestHandler<T = void>(
	request: Promise<AxiosResponse<ResponseParams<T>, any>>,
	options?: RequestHandlerOptionsParams,
) {
	const { throwData } = options || {};

	try {
		const response = await request;
		return response?.data?.content;
	} catch (error) {
		if (error instanceof AxiosError) {
			if (throwData) {
				throw (error as any)?.response.data;
			}

			throw (error as any)?.response?.data?.status;
		}

		throw error;
	}
}
