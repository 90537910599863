import { Flex, Icon, Progress, Typography } from 'front-commons/ds';
import { SourceCardProps } from '../../interfaces';
import { Container, Content, Item, Trigger } from './styles';
import ProductCard from '../ProductCard';

export default function SourceCard({
	data,
	posId,
	loadingBasketType,
	deletingProductId,
	hasEditBasketPermission,
	handleOpenDrawer,
	handleRemoveFromBasket,
}: SourceCardProps) {
	const sourceName = data?.businessUnitName || data.industryName!;
	const reachedMinValue = data.diffToMinimum <= 0;
	const currentValue = data.minimumValueForSale - data.diffToMinimum;
	const percent = ((currentValue / data.minimumValueForSale) * 100).toFixed(2);

	return (
		<Container backgroundColor="--surface-white" padding="16px 16px 24px">
			<Item value={sourceName} direction="column" gap="0" width="100%">
				<Trigger padding="0" cursor="pointer" width="100%" justifyContent="space-between" alignItems="center" gap="8px">
					<Flex direction="column" gap="4px" width="100%">
						<Flex alignItems="center" justifyContent="space-between" gap="8px">
							<Typography variant="ParagraphSmall/Semibold" align="start">
								{sourceName}
							</Typography>
							<Typography
								align="end"
								color={reachedMinValue ? '--semantic-success-base' : '--text-primary'}
								variant="Caption/Regular"
							>
								{reachedMinValue
									? 'Valor mínimo atingido'
									: `Faltam ${data.diffToMinimum.toCurrency()} para o valor mínimo`}
							</Typography>
						</Flex>
						<Progress
							height="2px"
							percent={Number(percent)}
							hiddenPercentage
							backgroundColor="--border-primary"
							predominantColor={reachedMinValue ? '--semantic-success-base' : '--semantic-info-text'}
						/>
					</Flex>

					<Icon name="expand_more" size="24px" color="--text-link" weight={500} />
				</Trigger>
				<Content padding="16px 0 0" direction="column" gap="24px">
					{data.products.map((product) => (
						<ProductCard
							key={product.id}
							data={product}
							posId={posId}
							sourceData={data}
							loadingBasketType={loadingBasketType}
							deletingProductId={deletingProductId}
							hasEditBasketPermission={hasEditBasketPermission}
							handleOpenDrawer={handleOpenDrawer}
							handleRemoveFromBasket={handleRemoveFromBasket}
						/>
					))}
				</Content>
			</Item>
		</Container>
	);
}
