import { Button, Flex, Grid, Icon, notify, Skeleton, Typography } from 'front-commons/ds';
import { useEffect, useState } from 'react';
import { BasketFullParams } from 'services/basket/interfaces';
import useBasket from 'stores/basket';
import useDrawer from 'stores/drawer';
import DistributorCard from './components/DistributorCard';
import useCustomer from 'stores/customer';
import usePos from 'stores/pos';
import CartTotals from './components/CartTotals';
import useDialog from 'stores/dialog';
import { getBasketSuggestion } from 'services/basket';
import { useNavigate } from 'react-router-dom';

export default function SideBasketDrawer() {
	const navigate = useNavigate();
	const [loadingSuggestions, setLoadingSuggestions] = useState(false);

	const { handleOpenDialog } = useDialog();
	const { handleHasPermission } = useCustomer();
	const { handleCloseDrawer, handleOpenDrawer } = useDrawer();
	const {
		posStore: {
			selectedPos: { id: posId },
		},
	} = usePos();
	const {
		basketStore: {
			basket: { baskets, discount, totalTaxSubstitution, totalPrice, finalTotalPrice },
			loading: loadingBasketType,
			deleting: deletingProductId,
		},
		basketCheckerHandler,
		handleGetBasketData,
		handleRemoveFromBasket,
	} = useBasket();

	const hasEditBasketPermission = handleHasPermission('BASKET_EDIT');

	const groupedByDistributors = Object.entries(
		baskets.reduce((acc, item) => {
			if (acc[item.distributorName]) acc[item.distributorName].push(item);
			if (!acc[item.distributorName]) acc[item.distributorName] = [item];

			return acc;
		}, {} as Record<string, BasketFullParams[]>),
	);

	const isEmpty = !groupedByDistributors.length;
	const skeletonQuantity = loadingBasketType === 'full' ? groupedByDistributors.length || 1 : 0;

	const handleCloseBasketDrawer = () => handleCloseDrawer('side-basket-drawer');

	const handleCleanCartClick = () => {
		handleOpenDialog({
			heading: { title: 'Limpar carrinho', showCloseButton: true },
			content: { description: 'Deseja realmente limpar o carrinho?' },
			footer: {
				primaryButton: { label: 'Não' },
				secondaryButton: {
					label: 'Sim',
					onClick: () => basketCheckerHandler.handleClearSelectedBaskets(true),
				},
			},
		});
	};

	const handleOrderRevisionClick = async () => {
		setLoadingSuggestions(true);

		try {
			const response = await getBasketSuggestion(posId, navigate);
			if ([200].includes(response.code)) {
				handleCloseBasketDrawer();
			}
			if ([204].includes(response.status)) {
				handleCloseBasketDrawer();
				navigate(`/revisar-pedido`);
			}
		} catch (err) {
			if (err === 409) {
				handleCloseBasketDrawer();
				navigate(`/revisar-pedido`);
			} else {
				notify.negative({ description: 'Ocorreu um erro ao revisar seu pedido.' });
			}
		} finally {
			setLoadingSuggestions(false);
		}
	};

	useEffect(() => {
		handleGetBasketData({ loading: 'full' });

		document.body.style.overflow = 'hidden';

		return () => {
			document.body.style.overflow = 'auto';
		};
	}, []);

	return (
		<Flex
			gap="0px"
			width="100%"
			height="100%"
			padding="16px 8px 16px 16px"
			direction="column"
			data-testid="side-basket"
		>
			<Flex
				as="header"
				margin="0 0 16px"
				alignItems="center"
				data-testid="side-basket-Header"
				justifyContent="space-between"
			>
				<Typography variant="Paragraph/Semibold">Itens no carrinho</Typography>

				<Button
					variant="none"
					onClick={() => handleCloseDrawer('side-basket-drawer')}
					leftIcon={{ name: 'close', size: '24px', weight: 500, color: '--text-link' }}
				/>
			</Flex>

			<Flex
				as="section"
				gap={{ small: '16px', medium: '24px' }}
				height="100%"
				padding="0 8px 24px 0"
				overflow="auto"
				direction="column"
				data-testid="side-basket-Content"
			>
				{Array.generate(skeletonQuantity).map((index) => (
					<DistributorCard.Skeleton key={index} />
				))}

				{isEmpty && !skeletonQuantity && (
					<Flex direction="column" alignItems="center" gap="8px" width="100%" maxWidth="335px" margin="48px auto 0">
						<Icon name="shopping_cart" size="48px" color="--text-secondary" />

						<Typography align="center" variant="Subtitle" lineHeight="28px">
							Seu carrinho está vazio
						</Typography>

						<Typography align="center">
							Você pode navegar pelo menu ou utilizar a busca para encontrar o que deseja.
						</Typography>
					</Flex>
				)}

				{!skeletonQuantity &&
					groupedByDistributors.map(([distributorName, group]) => (
						<DistributorCard
							key={distributorName}
							name={distributorName}
							posId={posId}
							sources={group}
							loadingBasketType={loadingBasketType}
							deletingProductId={deletingProductId}
							hasEditBasketPermission={hasEditBasketPermission}
							handleOpenDrawer={handleOpenDrawer}
							handleRemoveFromBasket={handleRemoveFromBasket}
						/>
					))}
			</Flex>

			{!isEmpty && !skeletonQuantity && (
				<Flex
					as="footer"
					gap="16px"
					style={{ boxShadow: '0px -4px 24px 0px #00000029' }}
					width="calc(100% + 24px)"
					height="fit-content"
					margin="0 -16px -16px"
					padding="24px"
					direction="column"
					data-testid="side-basket-Footer"
				>
					<CartTotals tax={totalTaxSubstitution} brute={totalPrice} liquid={finalTotalPrice} discount={discount} />

					<Flex padding="8px 16px" borderRadius="8px" backgroundColor="--surface-primary" gap="8px">
						<Icon name="info" size="16px" fill color="--text-secondary" />

						<Typography variant="Caption/Regular" lineHeight="20px">
							Os preços poderão sofrer alteração por impostos praticados pelos distribuidores
						</Typography>
					</Flex>

					<Grid gap="16px" columns="1fr 1fr">
						<Button
							width="100%"
							variant="secondary"
							onClick={handleCleanCartClick}
							disabled={loadingSuggestions || !!loadingBasketType}
							hasPermission={hasEditBasketPermission}
						>
							Limpar carrinho
						</Button>

						<Button
							width="100%"
							onClick={handleOrderRevisionClick}
							loading={loadingSuggestions}
							disabled={!!loadingBasketType}
						>
							Revisar pedido
						</Button>
					</Grid>
				</Flex>
			)}
		</Flex>
	);
}
