import { DynamicLoadingParams } from 'front-commons/hooks/useDynamicLoading/interfaces';
import { DistributorsFiltersEnum, DistributorsFiltersExhibitionType } from 'services/pos/interfaces';

export type BusinessUnitNameTypes = 'Neo Química' | 'Marcas';

export interface DistributorProps {
	id: string;
	minimumValue: number;
	order?: number;
	summarizedCorporateName: string;
	commercialCenter: string;
}

export enum BusinessUnitResponseEnum {
	BusinessUnitId = 'businessUnitId',
	BusinessUnitName = 'businessUnitName',
	Distributors = 'distributors',
	IsPED = 'pedEnabled',
}

export interface BusinessUnitResponse {
	[BusinessUnitResponseEnum.BusinessUnitId]: string;
	[BusinessUnitResponseEnum.BusinessUnitName]: BusinessUnitNameTypes;
	[BusinessUnitResponseEnum.Distributors]: DistributorProps[];
	[BusinessUnitResponseEnum.IsPED]?: boolean;
}

export interface DistributorsSourceParams {
	id: BusinessUnitEntity['businessUnitId'] | IndustryEntity['industryId'];
	type: 'businessUnit' | 'industry';
	name: BusinessUnitNameTypes | IndustryEntity['industryName'];
	distributors: DistributorProps[];
	pedEnabled?: boolean;
}

export type DistributorsLoadingTypes = DynamicLoadingParams<'POS' | 'DISTRIBUTORS' | 'CONTENT'>;

export interface CustomerFiltersDataParams {
	[DistributorsFiltersEnum.CommercialFlag]: string | undefined;
	[DistributorsFiltersEnum.Region]: string[] | undefined;
	pos: string | string[] | undefined;
	exhibitionType?: DistributorsFiltersExhibitionType;
}

export interface DistributorsFiltersProps {
	initialPos?: string;
	hasFiltersChanged?: boolean;

	applyFilters(simplePosId?: string): void
	updateActualFilters(payload: Partial<CustomerFiltersDataParams>): void
}