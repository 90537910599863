import { DistributorsSourceParams } from 'pages/pos/Distributors/interfaces';
import type { PosParams, PosReducer } from './interfaces';

export const setMyPos = (myPos: PosParams[]) => ({
	type: 'SET_MY_POS',
	payload: { myPos },
});

export const setSelectedPos = (pos: PosReducer['selectedPos']) => ({
	type: 'SET_SELECTED_POS',
	payload: { pos },
});

export const setPosLoading = (loading: PosReducer['loading']) => ({
	type: 'SET_POS_LOADING',
	payload: { loading },
});

export const setPosDistributors = (distributors: Concrete<PosReducer['selectedPos']['distributors']>) => ({
	type: 'SET_POS_DISTRIBUTORS',
	payload: { distributors },
});

export const setCleanPos = () => ({
	type: 'SET_CLEAN_POS',
});

export const setUpdateDistributor = (distributor: DistributorsSourceParams) => ({
	type: 'SET_UPDATE_DISTRIBUTOR',
	payload: { distributor },
});

export const setHasDistributor = (hasDistributor: boolean) => ({
	type: 'SET_HAS_DISTRIBUTOR',
	payload: { hasDistributor },
});

export const managePosRegisterData = (action: 'SET' | 'CLEAR', data?: string) => ({
	type: 'MANAGE_POS_REGISTER_DATA',
	payload: { action, data },
});
